import Vue from 'vue'
import { Toast } from 'vant';
import { Notify } from 'vant';
import { Dialog } from 'vant';
import App from './App.vue'

Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  Toast,
  Notify,
  Dialog
}).$mount('#app')

<template>
  <div id="app">
    <div class="page flex-col">
      <div class="block_1 flex-col justify-end">
        <van-nav-bar safe-area-inset-top  class="nav-bar_1 NavBar">
          <template #title>
            <span class="text_2">树洞小酒馆app</span>
          </template>
        </van-nav-bar>
      </div>
      <div class="block_2 flex-col justify-end" :style="{background:'url('+ barData.cover_img +') 100% no-repeat'}">
        <img class="single-avatar_1" referrerpolicy="no-referrer"
          :src="barData.avatar" />
        <div class="text-group_1 flex-col justify-between">
          <span class="text_3">{{barData.bar_name}}</span>
          <span class="text_4">{{barData.bar_welcome}}</span>
        </div>
        <div class="group_1 flex-col" :style="{background:'url('+ bgA +') 100% no-repeat'}">
          <img class="image_1" referrerpolicy="no-referrer"
            :src="bgC"/>
          <span class="paragraph_1">匿名和我聊天<br />和我说任何<br />你想和我说的话</span>
        </div>
      </div>
      <van-action-sheet v-model="show" :overlay="false" :close-on-click-overlay="false">
        <div class="block_3 flex-col">
          <div class="box_1 flex-col" :style="{background:'url('+ bgB +') 100% no-repeat'}">
            <div class="box_2 flex-row justify-between">
              <span class="text_5">匿名悄悄话</span>
              <button class="button_1 flex-col" @click="sendBarMessage" :disabled="barLink == ''">
                <span class="text_6">发送</span>
              </button>
            </div>

            <div class="text-wrapper_1 flex-col">
              <van-field size="medium" class="chat_input" v-model="message" rows="5" autosize
                type="textarea" placeholder="Ta不知道你的真实身份
请不要发送恶意、冒犯别人等违规消息
发送违规消息我们会禁止你再使用该功能" />

            </div>
            <div class="image-text_1 flex-row justify-between">
              <span class="text-group_2" @click="showCreateBar">{{ createdBar ? "查看" : "创建"}}我的蒙面小酒馆<van-icon class="icon_2" name="arrow" /></span>
            </div>
          </div>
        </div>
      </van-action-sheet>

      <van-action-sheet v-model="show2">
        <div class="box_4 flex-col">
          <div class="group_2 flex-col" :style="{background:'url('+ bgB +') 100% no-repeat'}">
            <span class="text_5">创建你的收件箱</span>
            <div class="section_1 flex-col justify-between">
              <van-form validate-trigger="onSubmit" @submit="handleLogin">
              <div class="section_2 flex-col">
                  <van-field class="text_6" v-model="phone" name="phone" placeholder="请填写手机号"
                    :rules="[{ required: true }]" />
                  <van-field class="text-wrapper_1" v-model="code" center name="code" placeholder="请填写验证码"
                    :rules="[{ required: true }]">
                    <template #button>

                      <van-button native-type="button" size="small" type="primary" class="text_8" @click="handleGetPhoneCode" v-if="canSms"><span style="color:#FFE599 ">发送验证码</span></van-button>
                      <van-count-down :time="smsTime" ref="countDown" :auto-start="false" @finish="handleFinishSmsTime" v-show="!canSms">
                        <template #default="timeData">
                          <span class="time_block">{{ timeData.seconds }}</span>
                        </template>
                      </van-count-down>
                    </template>
                  </van-field>
              </div>
                <van-button class="button_1" type="info" native-type="submit" block>
                  创建我的收件箱
                </van-button>
              </van-form>
            </div>
            <div class="section_3 flex-row justify-between">
              <div class="image-text_1 flex-row justify-between">
                <van-checkbox name="Checkbox_checkbox_1" v-model="agree" icon-size="4vw" checked-color=""
                  class="checkbox_1 Checkbox" referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd8bbe1f9d6057d86ece2a958ac9c57a5b18bf9146e85b85e99798ce519c1a3d0"></van-checkbox><span
                  class="text-group_2">我已经阅读并同意</span>
              </div>
              <span class="text_10" @click="handleHref(xieyi1)">《服务协议》</span>
              <span class="text_11">和</span>
              <span class="text_12" @click="handleHref(xieyi2)">《隐私协议》</span>
            </div>
          </div>
        </div>
      </van-action-sheet>

      <van-action-sheet v-model="show3">
        <div class="create_box_3 flex-col">
          <span class="create_text_5">你的专属，蒙面小酒馆</span>
          <van-form validate-first @submit="handleCreateBar">
          <div class="create_box_4 flex-row justify-start">
            <span class="create_text_6 flex-col">{{barPrefixUrl}}</span>
            <div class="create_input_1 flex-col Input">
              <van-field
                      class="create_text_7 bar_link_input"
                      placeholder="输入你的小酒馆链接"
                      v-model="myBarLink"
                      :disabled="createdBar"
              ></van-field>
            </div>
          </div>
          <span class="create_text_8" v-if="!createdBar"
          >请输入5-12个字符，仅限英文字母和数字，字母不区分大小写</span
          >
          <van-button class="create_button_1 flex-col" type="info" native-type="submit" v-if="!createdBar">
            <span class="create_text_9">创建</span>
          </van-button>
            <van-button class="create_button_1 flex-col" type="info" native-type="button" v-if="createdBar" @click="handelCopyBarLink">
              <span class="create_text_9">复制</span>
            </van-button>
          </van-form>
        </div>
      </van-action-sheet>
    </div>

    <van-number-keyboard safe-area-inset-bottom />
  </div>
</template>

<script>
import { ActionSheet, Col, Row, Button, Field, Icon, Image, NavBar, NumberKeyboard, Checkbox, Form,CountDown } from 'vant';
import {Login, BarInfo, SendBarMsg, GetPhoneCode, CreateMyBar} from "@/api/bar";
import bgA from './assets/a.png'
import bgB from './assets/b.png'
import bgC from './assets/c.png'
import { store } from '@/store'

export default {
  name: 'App',
  components: {
    [ActionSheet.name]: ActionSheet,
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [NavBar.name]: NavBar,
    [NumberKeyboard.name]: NumberKeyboard,
    [Checkbox.name]: Checkbox,
    [Form.name]: Form,
    [CountDown.name]: CountDown,
  },
  data() {
    return {
      bgA:bgA,
      bgB:bgB,
      bgC:bgC,
      barPrefixUrl:"https://bar.hole.diandi.club/",
      barLink: "",
      show: true,
      show2: false,
      show3: false,
      agree: true,
      message: "",
      phone: "",
      code: "",
      token: "",
      barData:{
        bar_name: "X小姐的蒙面小酒馆",
        bar_welcome: "欢迎光临",
        avatar: "https://youth-2.oss-cn-hangzhou.aliyuncs.com/defaultAvatar/girl.png",
        cover_img: "https://youth-2.oss-cn-hangzhou.aliyuncs.com/defaultAvatar/girl.png",
      },
      canSms: true,
      smsTime: 60 *1000,
      myBarLink: "",
      xieyi1: 'http://h5.diandi.club/hole_service.html',
      xieyi2: 'http://h5.diandi.club/hole_private.html',
      createdBar: false,
    };
  },
  methods: {
    validator(val) {
      return /[0-9A-Za-z]{5,12}/.test(val);
    },
    phoneValidator(val){
      return /1\d{10}/.test(val)
    },
    smsValidator(val) {
      return /\d{4}/.test(val)
    },
    sendBarMessage() {
      if (this.token == "") {
        this.$notify({type: 'primary', message: '请先创建你的收件箱'});
        this.show2 = true;
      } else {
        if (this.message == "") {
          this.$toast.fail("请填写你想说的悄悄话")
        } else {
          this.doSendBarMsg()
        }
      }
    },
    async doSendBarMsg(){
      let res = await SendBarMsg({bar_url:this.barLink, message:this.message, source:"bar_h5"})
      if(res.code === 0) {
        this.$dialog.confirm({
          title: '发送成功',
          message: '下载树洞小酒馆App\n' +
                  '\n' +
                  '查看Ta的回应并继续蒙面聊天',
          confirmButtonText: '前往下载',
          cancelButtonText: '知道了',
        }).then(() => {
          window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.drink.hole'
        }).catch(() => {

        });
      }else{
        this.$toast.fail(res.message)
      }
    },
    async doCreateMyBar(){
      let res = await CreateMyBar({bar_name:this.myBarLink, source:"bar_h5"})
      if(res.code === 0) {
        this.createdBar = true
        this.$dialog.confirm({
          title: '创建成功',
          message: '下载树洞小酒馆App\n' +
                  '\n' +
                  '查看Ta的回应并继续蒙面聊天',
          confirmButtonText: '前往下载',
          cancelButtonText: '知道了',
        }).then(() => {
          window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.drink.hole'
        }).catch(() => {
          this.show3 = false;
        });
      }else{
        this.$toast.fail(res.message)
      }
    },
    handleCreateBar(){
      if (this.token == "") {
        this.$notify({type: 'primary', message: '请先创建你的收件箱'});
        this.show2 = true;
      } else {
        if(!this.validator(this.myBarLink)) {
          this.$toast.fail("请填写正确的小酒馆链接")
          // window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.drink.hole'
        }else{
          this.doCreateMyBar()
        }
      }
    },
    handleGetPhoneCode(){
      if (this.phone == "") {
        this.$toast.fail("请填写手机号码")
      } else {
        if(!this.phoneValidator(this.phone)){
          this.$toast.fail("请填写正确的手机号码")
        }else {
          this.doGetPhoneCode()
        }
      }
    },
    handleFinishSmsTime(){
      this.canSms = true
      this.$refs.countDown.reset();
    },
    handelCopyBarLink(){
      let text = this.barPrefixUrl + this.myBarLink
      const input = document.createElement('input');
      input.value = text;//将需要复制的内容赋值给input框
      document.body.appendChild(input);
      input.select()//选中输入框
      document.execCommand('Copy')//复制当前选中文本到前切板
      document.body.removeChild(input);
      this.$toast.success("已复制")
    },
    async doGetPhoneCode(){
      let res = await GetPhoneCode({phone: this.phone})
      if(res.code === 0) {
        this.$toast.success("发送成功")
        this.canSms = false
        this.$refs.countDown.start();
      }else{
        this.$toast.fail(res.message)
      }
    },
    async doGetBarInfo(){
      let res = await BarInfo({bar_url:this.barLink})
      if(res.code === 0) {
        if(res.data.bar_url != "") {
          this.barData = res.data
          if(this.barData['bar_url_path'] != undefined && this.barData['bar_url_path'] !== "") {
            this.createdBar = true
            this.myBarLink = this.barData['bar_url_path']
          }
        }
      }else{
        this.$toast.fail(res.message)
      }
    },
    handleLogin(){
      if(this.agree == false) {
        this.$notify({type: 'primary', message: '请阅读并同意服务和隐私协议'});
      } else {
        if (!this.phoneValidator(this.phone)) {
          this.$toast.fail("请填写正确的手机号码")
        } else if (!this.smsValidator(this.code)) {
          this.$toast.fail("请填写正确的验证码")
        } else {
          this.doLogin()
        }
      }
    },
    async doLogin(){
      let inviteUser = 0
      if(Object.keys(this.barData).length > 0) {
        inviteUser = this.barData.user_id
      }
      let res = await Login({phone:this.phone, code: this.code, from_source:"bar_h5", user_id:inviteUser})
      if(res.code === 0) {
        this.token = res.data.token
        store.commit('user/setToken', this.token)
        this.$toast.success("登入成功")
        await this.doGetBarInfo()
        this.show2 = false
      } else{
        this.$toast.fail(res.message)
      }
    },
    showCreateBar() {
      if (this.token == "") {
        this.$notify({type: 'primary', message: '请先创建你的收件箱'});
        this.show2 = true;
      }else {
        this.show3 = true;
      }
    },
    handleHref(url){
      window.location.href = url
    }
  },
  mounted() {
    let url = new URL(window.location.href)
    let urlPath = url.pathname.split("/")
    if (urlPath.length == 2 && urlPath[1] != "") {
      this.barLink = urlPath[1]
      this.doGetBarInfo()
    } else {
      this.$toast.fail("请访问正常的小酒馆链接")
    }
    this.token = store.getters['user/token']
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.bar_link_input input.van-field__control {
  color: white;
}

</style>
<style lang="scss" scoped>
body * {
  box-sizing: border-box;
  flex-shrink: 0;
}

body {
  font-family: PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma,
    Arial, PingFang SC-Light, Microsoft YaHei;
}

input {
  background-color: transparent;
  border: 0;
}

button {
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  outline: none;
  background-color: transparent;
}

button:active {
  opacity: 0.6;
}

.van-nav-bar__left:active,
.van-nav-bar__right:active {
  opacity: 1;
}

[class*='van-']::after {
  border-bottom: 0;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.justify-start {
  display: flex;
  justify-content: flex-start;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}

.justify-around {
  display: flex;
  justify-content: space-around;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.align-start {
  display: flex;
  align-items: flex-start;
}

.align-center {
  display: flex;
  align-items: center;
}

.align-end {
  display: flex;
  align-items: flex-end;
}
</style>
<style lang="scss" scoped>
.page {
  background-color: rgba(248, 248, 248, 1);
  position: relative;
  width: 750px;
  height: 100vh;
  overflow: hidden;

  .block_1 {

    background-color: rgba(255, 255, 255, 1);
    width: 750px;
    height: 100px;

    .nav-bar_1 {
      width: 750px;
      height: 100px;
      background: none;

      .text_2 {
        padding-top: 20px;
        overflow-wrap: break-word;
        color: rgba(4, 0, 0, 1);
        font-size: 30px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        line-height: 200px;
        text-align: center;
        white-space: nowrap;
      }

    }
  }

  .block_2 {
    width: 620px;
    height: 864px;
    border-radius: 20px;
    background-size: cover !important;
    margin: 60px 0 524px 65px;

&:before {
   content: "";
   display: block;
   position: absolute;
   width: 620px;
   height: 864px;
   border-radius: 20px;
   background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
 }
    .single-avatar_1 {
      width: 236px;
      height: 236px;
      margin: 60px 0 0 192px;
      border-radius: 120px;
      backdrop-filter: blur(10px);
      z-index:2;
      object-fit:cover;
    }

    .text-group_1 {
      width: 250px;
      height: 92px;
      margin: 40px 0 0 185px;
      backdrop-filter: blur(10px);
      z-index:2;
      .text_3 {
        width: 250px;
        height: 40px;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 28px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        line-height: 40px;
      }

      .text_4 {
        width: 120px;
        height: 42px;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 30px;
        font-weight: NaN;
        text-align: left;
        white-space: nowrap;
        line-height: 42px;
        margin: 10px 0 0 65px;
      }

    }

    .group_1 {
      width: 560px;
      height: 388px;
      border-radius: 32px 32px 0 0;
      margin: 48px 0 0 30px;
      backdrop-filter: blur(10px);
      z-index:2;
      .image_1 {
        width: 118px;
        height: 42px;
        margin: 30px 0 0 221px;
      }

      .paragraph_1 {
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14);
        width: 331px;
        height: 216px;
        overflow-wrap: break-word;
        color: rgba(4, 0, 0, 1);
        font-size: 48px;
        font-family: AlibabaPuHuiTi_2_75_SemiBold;
        font-weight: NaN;
        text-align: left;
        line-height: 72px;
        margin: 34px 0 66px 60px;
      }

    }
  }

  .block_3 {
    background-color: rgba(216, 216, 216, 1);
    border-radius: 32px 32px 0px 0px;
    height: 580px;
    width: 750px;
    left: 0;
    top: 1044px;

    .box_1 {
      width: 750px;
      height: 580px;
      background-size: 100% 100% !important;

      .box_2 {
        width: 630px;
        height: 96px;
        margin: 20px 0 0 60px;

        .text_5 {
          width: 170px;
          height: 48px;
          overflow-wrap: break-word;
          color: rgba(4, 0, 0, 1);
          font-size: 40px;
          font-family: Fontquan-XinYiGuanHeiTi-Regular;
          font-weight: NaN;
          text-align: left;
          white-space: nowrap;
          line-height: 48px;
          margin-top: 24px;
        }

        .button_1 {
          background-color: rgba(255, 190, 0, 1);
          border-radius: 32px;
          height: 96px;
          width: 180px;

          .text_6 {
            width: 60px;
            height: 42px;
            overflow-wrap: break-word;
            color: rgba(4, 0, 0, 1);
            font-size: 30px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            text-align: left;
            white-space: nowrap;
            line-height: 42px;
            margin: 27px 0 0 60px;
          }

        }
      }

      .text-wrapper_1 {
        box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.06);
        background-color: rgba(255, 255, 255, 1);
        border-radius: 32px;
        height: 350px;
        width: 630px;
        margin: 20px 0 0 60px;
      }
      .chat_input {
        border-radius: 32px;
      }
      .image-text_1 {
        width: 260px;
        height: 37px;
        margin: 14px 0 0 241px;

        .text-group_2 {
          width: 234px;
          height: 37px;
          overflow-wrap: break-word;
          color: rgba(4, 0, 0, 1);
          font-size: 26px;
          font-weight: NaN;
          text-align: left;
          white-space: nowrap;
          line-height: 37px;
        }

        .icon_2 {
          width: 10px;
          height: 20px;
          margin-top: 9px;
        }

      }
    }
  }

  .box_4 {
    background-color: rgba(216, 216, 216, 1);
    border-radius: 16px 16px 0px 0px;
    height: 580px;
    width: 750px;
    left: 0;
    top: 1044px;

    .group_2 {
      width: 750px;
      height: 580px;
      background-size: 100% 100% !important;

      .text_5 {
        width: 238px;
        height: 48px;
        overflow-wrap: break-word;
        color: rgba(4, 0, 0, 1);
        font-size: 40px;
        font-family: Fontquan-XinYiGuanHeiTi-Regular;
        font-weight: NaN;
        text-align: left;
        white-space: nowrap;
        line-height: 48px;
        margin: 44px 0 0 60px;
      }

      .section_1 {
        width: 630px;
        /*height: 375px;*/
        margin: 44px 0 0 60px;

        .section_2 {
          box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.06);
          background-color: rgba(255, 255, 255, 1);
          border-radius: 40px;
          width: 630px;
          justify-content: flex-center;

          .text_6 {
            width: 500px;
            height: 80px;
            overflow-wrap: break-word;
            color: rgba(179, 179, 179, 1);
            font-size: 26px;

            text-align: left;
            white-space: nowrap;
            line-height: 40px;
            margin: 17px 0 17px 15px;
          }

          .group_3 {
            background-color: rgba(14, 58, 75, 0.1);
            border-radius: 40px;
            width: 570px;
            height: 1px;
            margin: 33px 0 0 30px;
          }

          .text-wrapper_1 {
            width: 570px;
            height: 80px;
            margin: 17px 0 17px 15px;
            overflow-wrap: break-word;
            line-height: 40px;
            color: rgba(179, 179, 179, 1);
            font-size: 26px;
            font-weight: NaN;
            text-align: left;
            white-space: nowrap;

            .text_8 {
              width: 110px;
              overflow-wrap: break-word;
              color: rgba(255, 229, 153, 1);
              font-size: 22px;
              font-weight: NaN;
              text-align: right;
              white-space: nowrap;
              line-height: 22px;
              margin-top: 8px;
            }
            .time_block {
              width: 80px;
              display: inline-block;
              height: 40px;
              line-height: 40px;
              color: black;
              font-size: 22px;
              font-weight: bold;
              text-align: center;
              background-color: rgb(255, 190, 0);
              border-radius: 5px;
            }
          }
        }

        .button_1 {
          box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.06);
          background-color: rgba(255, 190, 0, 1);
          border-radius: 40px;
          /*height: 104px;*/
          margin-top: 20px;
          /*width: 630px;*/
          font-size: 30px;
          font-weight: 500;
          color: rgba(4, 0, 0, 1);
          font-family: PingFangSC-Medium;
          white-space: nowrap;
          overflow-wrap: break-word;
        }
      }

      .section_3 {
        width: 494px;
        height: 100px;
        margin: 0 auto;
        align-items: center;
        .image-text_1 {
          height: 30px;
          .checkbox_1 {
            width: 30px;
            height: 30px;
          }

          .text-group_2 {
            /*width: 190px;*/
            height: 30px;
            overflow-wrap: break-word;
            color: rgba(34, 34, 34, 1);
            font-size: 22px;
            font-family: AlibabaPuHuiTi_2_45_Light;
            font-weight: NaN;
            text-align: left;
            white-space: nowrap;
            line-height: 30px;
          }
        }

        .text_10 {
          /*width: 140px;*/
          height: 30px;
          overflow-wrap: break-word;
          color: rgba(34, 34, 34, 1);
          font-size: 22px;
          font-family: AlibabaPuHuiTi_2_45_Light;
          font-weight: bold;
          text-align: left;
          white-space: nowrap;
          line-height: 30px;
        }

        .text_11 {
          /*width: 22px;*/
          height: 30px;
          overflow-wrap: break-word;
          color: rgba(34, 34, 34, 1);
          font-size: 22px;
          font-family: AlibabaPuHuiTi_2_45_Light;
          font-weight: NaN;
          text-align: left;
          white-space: nowrap;
          line-height: 30px;
        }

        .text_12 {
          /*width: 150px;*/
          height: 30px;
          overflow-wrap: break-word;
          color: rgba(34, 34, 34, 1);
          font-size: 22px;
          font-family: AlibabaPuHuiTi_2_45_Light;
          font-weight: bold;
          text-align: left;
          white-space: nowrap;
          line-height: 30px;
        }
      }
    }
  }

.create_box_3 {
  background-color: rgba(21, 17, 45, 1);
  border-radius: 15px 15px 0px 0px;
  left: -65px;
  top: 370px;
  width: 750px;
  height: 436px;
.create_text_5 {
  width: 300px;
  height: 42px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 30px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  line-height: 42px;
  margin: 27px 0 0 30px;
}
.create_box_4 {
  width: 695px;
  height: 100px;
  margin: 27px 0 0 28px;
.create_text_6 {
  /*width: 269px;*/
  height: 42px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 30px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 42px;
  margin-top: 29px;
}
.create_input_1 {
  background-color: rgba(45, 47, 66, 1);
  border-radius: 26px;
  height: 100px;
  border: 1px solid rgba(56, 58, 74, 1);
  flex-shrink: 1;
.create_text_7 {
  overflow-wrap: break-word;
  color: rgba(179, 179, 179, 1);
  font-size: 30px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 100px;
  padding: 0 0 0 20px;
  background: none;
  border: 0;
  flex: auto;
}
}
}
.create_text_8 {
  width: 690px;
  height: 84px;
  overflow-wrap: break-word;
  color: rgba(179, 179, 179, 1);
  font-size: 26px;
  font-weight: NaN;
  text-align: left;
  line-height: 42px;
  margin: 20px 0 0 30px;
}
.create_button_1 {
  background-color: rgba(255, 190, 0, 1);
  border-radius: 40px;
  height: 96px;
  width: 690px;
  margin: 30px 0 10px 30px;
.create_text_9 {
  width: 64px;
  height: 45px;
  overflow-wrap: break-word;
  color: rgba(4, 0, 0, 1);
  font-size: 32px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: justify;
  white-space: nowrap;
  line-height: 45px;
  margin: 26px 0 0 313px;
}
}
}
}</style>

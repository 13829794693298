export const user = {
  namespaced: true,
  state: {
    token: ''
  },
  mutations: {
    setToken(state, token) {
      // 这里的 `state` 对象是模块的局部状态
      state.token = token
    },
  },
  actions: {

  },
  getters: {
    token(state) {
      return state.token
    },
  }
}

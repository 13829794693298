import service from '@/utils/request'

// @Summary 用户登录
// @Produce  application/json
// @Param data body {phone:"string",code:"string"}
// @Router /v1/logincode [post]
export const Login = (data) => {
    return service({
        url: '/v1/logincode',
        method: 'post',
        data: data
    })
}

// @Summary 获取小酒馆信息
// @Produce  application/json
// @Param data body {bar_url:"string"}
// @Router /v1/webbarinfo [post]
export const BarInfo = (data) => {
    return service({
        url: '/v1/webbarinfo',
        method: 'post',
        data: data
    })
}

// @Summary 发送小酒馆悄悄话
// @Produce  application/json
// @Param data body {message:"string", "bar_url":"string", "source":"string"}
// @Router /v1/sendbarmessage [post]
export const SendBarMsg = (data) => {
    return service({
        url: '/v1/sendbarmessage',
        method: 'post',
        data: data
    })
}


// @Summary 发送手机登入的验证码
// @Produce  application/json
// @Param data body {phone:"string"}
// @Router /v1/sendsms [post]
export const GetPhoneCode = (data) => {
    return service({
        url: '/v1/sendsms',
        method: 'post',
        data: data
    })
}


// @Summary 创建我的小酒馆
// @Produce  application/json
// @Param data body {phone:"string"}
// @Router /v1/createmybar [post]
export const CreateMyBar = (data) => {
    return service({
        url: '/v1/createmybar',
        method: 'post',
        data: data
    })
}

